
import { defineComponent, ref, nextTick } from "vue";
import {
  generateReport,
  postFinalSave,
  getWsVersion,
  postFinalEnable,
  postFinalEdit,
  setBlueinvoiceLock
} from "@/API/closing";
import { message } from "ant-design-vue";
import First from "@/views/Closing/Tab/Report/First.vue";
import Second from "@/views/Closing/Tab/Report/Second.vue";
import Third from "@/views/Closing/Tab/Report/Third.vue";
import Fourth from "@/views/Closing/Tab/Report/Fourth.vue";
import blobDownload from "@/utils/blobDownload";
type VersionDataType = {
  id: number;
  planningName: string;
  currentKufri: string;
};
export default defineComponent({
  components: {
    First,
    Second,
    Third,
    Fourth
  },
  emits: ["chang-tab", "change-id","change-final"],
  props: {
    makeId: {
      required: true,
      type: Number
    },
    make: {
      type: String,
      default: ""
    },
    isfinal: {
      type: Boolean,
      default: true
    },
    cbVersion: {
      type: String,
      default: ""
    },
    makeYear: {
      type: String,
      default: ""
    },
    makeMonth: {
      type: String,
      default: ""
    }
  },

  setup(props, { emit }) {
     const visible = ref(false);
    const activeKey = ref("1");
    const tdValue = ref("1");
    const report1 = ref();
    const report2 = ref();
    const report3 = ref();
    const report4 = ref();
    const enumObj = {
      "1": () => {
        report1.value.initData();
      },
      "2": () => {
        report2.value.initData();
      },
      "3": () => {
        report3.value.initData();
      },
      "4": () => {
        report4.value.initData();
      }
    };
    const planningId = ref<number>(0);
    const kufriVersion = ref("");
    const wsVersionArray = ref<VersionDataType[]>([]);
    // const year = props.makeYear;
    // const month = props.makeMonth;
    const initState = async () => {
      // 生成报表
      await generateReport({ mcId: props.makeId });
      const res = await getWsVersion({
        make: props.make,
        planningYear: props.makeYear
      });
      wsVersionArray.value = res;
      planningId.value = res[0]?.id || 0;
      kufriVersion.value = res[0]?.currentKufri || "";
      activeKey.value = "1";
      nextTick(() => {
        report1.value.initData();
      });
    };
    const exportFile = () => {
      const params = {
        url: `/pcapi/api/v1/reportData/exportReport`,
        params: {
          mcId: props.makeId,
          searchType: parseInt(tdValue.value)
        }
      };
      blobDownload(params);
    };
    const handleRefresh = () => {
      nextTick(() => {
        enumObj[activeKey.value]();
      });
    };

    // 设置报表最终版本
    const handleSave = () => {

      postFinalSave(props.makeId).then(res => {
        if (res.code === "0") {
          let newMcId;
          if (props.isfinal){
             newMcId =  props.makeId;
          } else {
             newMcId = res.data.mcId;
          }
          
          emit("change-id", newMcId);
          message.success("The final version was saved successfully");
        } else {
          message.error("The final version was saved failed");
        }
      });
    };

    const handleSelect = () => {
      wsVersionArray.value.forEach(item => {
        if (item.id === planningId.value) {
          kufriVersion.value = item.currentKufri;
        }
      });
    };
    // 切换Tab
    const handleTabChange = () => {
      nextTick(() => {
        enumObj[activeKey.value]();
      });
    };
    const handleEnable = () => {
      postFinalEnable(props.makeId).then(res => {
        if (res.code === "0") {
          const newMcId = res.data.mcId;
          emit("change-id", newMcId);
        }
      });
    };
    const handleUpdate = async () => {
      await setBlueinvoiceLock(props.makeId);
      emit(
        "chang-tab",
        "GenerateClosing",
        props.make,
        props.cbVersion,
        props.makeId,
        props.makeMonth,
         props.makeYear,
        "blueInvoice"
      );
    };

    const handleEdit = () => {
      postFinalEdit(props.makeId).then(res => {
        if (res.code === "0") {
          emit('change-final',false)
          const makeId = res.data.wipVersionMcId;
          emit(
            "chang-tab",
            "DataPreparation",
            props.make,
            res.data.wipVersionName,
            makeId,
            props.makeMonth,
             props.makeYear,
            ""
          );
        } else {
          message.error("Reopen failed");
        }
      });
    };
    const handleCancel = () => {
      visible.value = false;
    };
    const handleOk = () => {
      handleSave()
      visible.value = false;
    };
     const showModal = () => {
      visible.value = true;
    };
    return {
      activeKey,
      tdValue,
      handleRefresh,
      handleEdit,
      handleUpdate,
      handleTabChange,
      exportFile,
      handleSelect,
      handleSave,
      handleEnable,
      initState,
      wsVersionArray,
      report1,
      report2,
      report3,
      report4,
      kufriVersion,
      planningId,
      visible,
      handleCancel,
      handleOk,
      showModal
    };
  }
});
